/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations

==========================================================================================*/


export default {
  SET_LEADS(state, leads) {
    state.leads = leads
  },
  SET_LEADS_COUNT(state, number) {
    state.leadsCount = number
  },
  SET_SEARCH_CHATS(state, chats) {
    state.chats = chats
  },
  REMOVE_RECORD(state, itemId) {
      const userIndex = state.users.findIndex((u) => u.id == itemId)
      state.users.splice(userIndex, 1)
  },
}
