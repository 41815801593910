<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
    <feather-icon
      icon="EyeIcon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="viewRecord()"
    />
    <vs-popup code-toggler title="Detalhes do lead" :active.sync="popupActive">
      <p>
        URL:
        <b>{{url}}</b>
      </p>
      <vs-table stripe :data="chatTranscript">
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>{{data[indextr].label}}</vs-td>
            <vs-td>{{data[indextr].text}}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-popup>
  </div>
</template>

<script>
import axios from "@/axios.js";
export default {
  props: {
    leadID: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      chatTranscript: [],
      popupActive: false,
      url: "",
      processing: false
    };
  },
  name: "CellRendererActions",
  methods: {
    viewRecord() {
      var leadID = this.params != null ? this.params.data.id : this.leadID;
      if (this.processing === true) {
        return;
      }
      this.processing = true;

      this.popupActive = true;
      axios
        .get("/p/chat/lead/" + leadID)
        .then(response => {
          var self = this;
          var elem = document.createElement("textarea");
          elem.innerHTML = response.data.data.url + response.data.data.uri;
          this.url = elem.innerText;
          this.chatTranscript = [];

          var obj = {};
          for (var i = 0; i < response.data.data.chatData.length; i++) {
            obj = response.data.data.chatData[i];
            Object.keys(obj).forEach(function(k) {
              self.chatTranscript.push({ label: k, text: obj[k] });
            });
          }
          this.processing = false;
        });
    }
  }
};
</script>
