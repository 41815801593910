<!-- =========================================================================================
    File Name: StatisticsCard.vue
    Description: Statistics card component
========================================================================================== -->

<template>
  <vx-card class="overflow-hidden vx-statistic" :title="statisticTitle" :cardHeader="cardHeader">
    <template slot="actions">
      <vs-button
        @click="helpCard = true"
        icon-pack="feather"
        icon="icon-help-circle"
        radius
        color="dark"
        type="flat"
        size="large"
      />
    </template>
    <div slot="no-body">
      <vs-popup
        class="helpcard"
        :title="statisticTitle"
        :active.sync="helpCard"
      >
        <p>{{ helpCardText }}</p>
      </vs-popup>
      <div
        class="p-6"
        :class="{
          'flex justify-between flex-row-reverse items-center': iconRight,
          'text-center': !iconRight && hideChart,
          '': !hideChart,
        }"
      >
        <feather-icon
          :icon="icon"
          class="p-3 inline-flex rounded-full"
          :class="[`text-${color}`, { 'mb-4': !iconRight }]"
          :style="{ background: `rgba(var(--vs-${color}),.15)` }"
        ></feather-icon>
        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ statistic }}</h2>
          <p :class="statisticDiff > 0 ? 'text-success' : 'text-danger'">
            {{ statisticDiff === 0 ? " " : statisticDiff + "%" }}
            <feather-icon
              v-if="statisticDiff !== 0"
              :icon="statisticDiff < 0 ? 'ArrowDownIcon' : 'ArrowUpIcon'"
              :svgClasses="[
                statisticDiff < 0 ? 'text-danger' : 'text-success',
                'stroke-current h-4 w-4 mb-1 mr-1',
              ]"
            ></feather-icon>
          </p>
        </div>
      </div>

      <div
        class="line-area-chart"
        :id="statisticTitle.replace(' ', '-')"
        v-if="chartData"
      >
        <vue-apex-charts
          ref="apexChart"
          :type="type"
          height="100"
          width="100%"
          :options="chartOptions"
          :series="chartData"
        ></vue-apex-charts>
      </div>
    </div>
  </vx-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import chartConfigs from "./chartConfigs.js";
import _color from "@/assets/utils/color.js";

export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticDiff: {
      type: [Number, String],
      required: false,
    },
    statisticDiffOrientation: {
      type: String,
      required: false,
      default: "",
    },
    statisticTitle: {
      type: String,
    },
    helpCardText: {
      type: String,
    },
    chartData: {
      type: Array,
      required: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    colorTo: {
      type: String,
    },
    chartType: {
      type: String,
      default: "line-chart",
    },
    type: {
      type: String,
      default: "line",
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    hideChart: {
      type: Boolean,
      default: false,
    },
    cardHeader: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      chartOptions: null,
      helpCard: false,
    };
  },
  watch: {
    themePrimaryColor() {
      this.$refs.apexChart.updateOptions({
        theme: { monochrome: { color: this.getHex(this.color) } },
      });
    },
  },
  computed: {
    themePrimaryColor() {
      return this.$store.state.themePrimaryColor;
    },
  },
  methods: {
    getHex(color) {
      if (_color.isColor(color)) {
        let rgb = window
          .getComputedStyle(document.documentElement)
          .getPropertyValue(`--vs-${color}`);
        rgb = rgb.split(",");
        return (
          "#" +
          (
            (1 << 24) +
            (Number(rgb[0]) << 16) +
            (Number(rgb[1]) << 8) +
            Number(rgb[2])
          )
            .toString(16)
            .slice(1)
        );
      }
      return color;
    },
    gradientToColor(color) {
      let gradientToColors = {
        primary: "#A9A2F6",
        success: "#55DD92",
        warning: "#ffc085",
        danger: "#F97794",
      };

      return gradientToColors[color]
        ? gradientToColors[color]
        : gradientToColors["primary"];
    },
  },
  components: {
    VueApexCharts,
  },
  created() {
    if (this.type === "area") {
      // assign chart options
      this.chartOptions = Object.assign({}, chartConfigs.areaChartOptions);

      this.chartOptions["theme"] = {
        monochrome: {
          enabled: true,
          color: this.getHex(this.color),
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      };
    } else if (this.type === "line") {
      // Assign chart options
      this.chartOptions = JSON.parse(
        JSON.stringify(chartConfigs.lineChartOptions)
      );

      this.chartOptions.fill.gradient.gradientToColors = [
        this.gradientToColor(this.colorTo || this.color),
      ];
      this.chartOptions.colors = [this.getHex(this.color)];
    }
  },
};
</script>
