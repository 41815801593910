/*=========================================================================================
  File Name: moduleUserManagement.js
  Description: Calendar Module
==========================================================================================*/


import state from './moduleLeadsManagementState.js'
import mutations from './moduleLeadsManagementMutations.js'
import actions from './moduleLeadsManagementActions.js'
import getters from './moduleLeadsManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

