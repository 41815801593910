/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions

==========================================================================================*/

import axios from "@/axios.js";

export default {
  fetchLeads({ commit }, payload) {
    return new Promise((resolve, reject) => {
      var params = { acc: "" + payload.acc + "" };
      if (payload.chat != null) params.chat = payload.chat;
      if (payload.limit != null) params.limit = payload.limit;
      if (payload.page != null) params.page = payload.page;
      if (payload.isLead != null) params.isLead = payload.isLead;

      axios.get("/p/chat/lead/list", {
        params: params,
      })
        .then((response) => {
          var i = 0;
          var currentLead = '';
          var d = '';
          var leads = [];
          var searchChats = [];
          var currentObjLead = {};
          if (typeof response.data.data !== "undefined") {
            const ChatsConfig = response.data.data.chatsConfig;
            for (var o = 0; o < ChatsConfig.length; o += 1)
              searchChats.push({ label: ChatsConfig[o].name, value: ChatsConfig[o]._id });

            if (typeof response.data.data.count !== "undefined")
              commit('SET_LEADS_COUNT', response.data.data.count)
            if (typeof response.data.data.items !== "undefined")
              for (i = 0; i < response.data.data.items.length; i++) {
                currentLead = response.data.data.items[i];

                let currentChat = ChatsConfig.find(el => el._id === currentLead.chat);

                currentObjLead = {};

                currentObjLead.id = currentLead._id;
                currentObjLead.chat = currentChat ? currentChat.name || "" : "";
                currentObjLead.name = (currentLead.name == null || (typeof currentLead.name === "undefined")) ? payload.NotInformed : currentLead.name;
                currentObjLead.email = (currentLead.email == null || (typeof currentLead.email === "undefined")) ? payload.NotInformed : currentLead.email;
                currentObjLead.phone = (currentLead.phone == null || (typeof currentLead.phone === "undefined")) ? payload.NotInformed : currentLead.phone;
                currentObjLead.status = (currentLead.complete) ? payload.Complete : payload.Incomplete;
                currentObjLead.channel = (typeof currentLead.channel !== "undefined") ? currentLead.channel.charAt(0).toUpperCase() + currentLead.channel.slice(1) : '';
                currentObjLead.isLead = (currentLead.isLead === true) ? payload.Lead : payload.Talk;
                currentObjLead.integration = currentLead.integration || 'Email';
                currentObjLead.intentions = [];
                for (let j in currentLead.intentions) {
                  if (currentObjLead.intentions.indexOf(currentLead.intentions[j]) == -1)
                    currentObjLead.intentions.push(currentLead.intentions[j]);
                }

                d = new Date(currentLead.createdAt);
                currentObjLead.date = (("0" + d.getDate()).slice(-2)) + '/' + (("0" + (d.getMonth() + 1)).slice(-2)) + '/' + d.getFullYear() || '';
                currentObjLead.hour = (("0" + d.getHours()).slice(-2)) + ':' + (("0" + d.getMinutes()).slice(-2)) + ':' + (("0" + d.getSeconds()).slice(-2)) || '';
                leads.push(currentObjLead);
              }
            commit('SET_LEADS', leads)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchUser(context, leadId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/lead-management/leads/${leadId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeRecord({ commit }, leadId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/lead-management/leads/${leadId}`)
        .then((response) => {
          commit('REMOVE_RECORD', leadId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
