var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{staticClass:"overflow-hidden vx-statistic",attrs:{"title":_vm.statisticTitle,"cardHeader":_vm.cardHeader}},[_c('template',{slot:"actions"},[_c('vs-button',{attrs:{"icon-pack":"feather","icon":"icon-help-circle","radius":"","color":"dark","type":"flat","size":"large"},on:{"click":function($event){_vm.helpCard = true}}})],1),_c('div',{attrs:{"slot":"no-body"},slot:"no-body"},[_c('vs-popup',{staticClass:"helpcard",attrs:{"title":_vm.statisticTitle,"active":_vm.helpCard},on:{"update:active":function($event){_vm.helpCard=$event}}},[_c('p',[_vm._v(_vm._s(_vm.helpCardText))])]),_c('div',{staticClass:"p-6",class:{
        'flex justify-between flex-row-reverse items-center': _vm.iconRight,
        'text-center': !_vm.iconRight && _vm.hideChart,
        '': !_vm.hideChart,
      }},[_c('feather-icon',{staticClass:"p-3 inline-flex rounded-full",class:[`text-${_vm.color}`, { 'mb-4': !_vm.iconRight }],style:({ background: `rgba(var(--vs-${_vm.color}),.15)` }),attrs:{"icon":_vm.icon}}),_c('div',{staticClass:"truncate"},[_c('h2',{staticClass:"mb-1 font-bold"},[_vm._v(_vm._s(_vm.statistic))]),_c('p',{class:_vm.statisticDiff > 0 ? 'text-success' : 'text-danger'},[_vm._v(" "+_vm._s(_vm.statisticDiff === 0 ? " " : _vm.statisticDiff + "%")+" "),(_vm.statisticDiff !== 0)?_c('feather-icon',{attrs:{"icon":_vm.statisticDiff < 0 ? 'ArrowDownIcon' : 'ArrowUpIcon',"svgClasses":[
              _vm.statisticDiff < 0 ? 'text-danger' : 'text-success',
              'stroke-current h-4 w-4 mb-1 mr-1',
            ]}}):_vm._e()],1)])],1),(_vm.chartData)?_c('div',{staticClass:"line-area-chart",attrs:{"id":_vm.statisticTitle.replace(' ', '-')}},[_c('vue-apex-charts',{ref:"apexChart",attrs:{"type":_vm.type,"height":"100","width":"100%","options":_vm.chartOptions,"series":_vm.chartData}})],1):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }